export enum PollStatus {
  list = 'list',
  completed = 'completed',
  voteFailed = 'voteFailed',
  alreadyVoted = 'alreadyVoted',
  voted = 'voted',
  validatedList = 'validatedList',
  validation = 'validation',
  resolutonDetails = 'resolutonDetails',
  resolutionWarning = 'resolutionWarning',
  vote = 'vote',
  voteResolution = 'voteResolution',
  validationResolution = 'validationResolution',
  incomplete = 'incomplete',
  electionsList = 'electionsList',
  multielectionRoundSelected = 'multielectionRoundSelected',
  listVote = 'listVote',
  listSelected = 'listSelected',
  eventList = 'eventList'
}
export type PollType = 'yes_no' | 'list' | 'grid';

export class VotingEvent {
  bc: boolean;
  description: string;
  endDate: string;
  image: string;
  is2FA: boolean;
  isFinished: boolean;
  publicVote: boolean;
  name: string;
  numberOfVote: number;
  opened: boolean;
  rounds: Array<Round>;
  sharedLinks?: string;
  shareResults: boolean;
  short_name: string;
  startDate: string;
  type: string;
  uuid: string;
  voters: number;
  votes: number;
  isLive: boolean;
  forceBlankVote: boolean;
}

export class Round {
  round: number;
    bc: boolean;
    questions: Array<ReceivedQuestion>;
    displayedQuestions?: Array<ReceivedQuestion>;
    title?: string;
    public_key: any;
    votes: number;
    results: Array<number>;
    displayedCandidates: string;
    opened: boolean;
}

export class Poll {
  bc: boolean;
  description: string;
  displayedQuestions?: Array<ReceivedQuestion>;
  endDate: string;
  image: string;
  is2FA: boolean;
  isFinished: boolean;
  isPublicVote: boolean;
  name: string;
  numberOfVote: number;
  public_key: any;
  questions: Array<ReceivedQuestion>
  rounds: number;
  sharedLinks?: string;
  shareResults: boolean;
  short_name: string;
  startDate: string;
  type: string;
  uuid: string;
  voters: number;
  votes: number;
  isLive: boolean;
  forceBlankVote: boolean;
  round: number;
  displayedCandidates: number;
  constructor(event: VotingEvent, round: number) {
    this.bc = event.rounds[round].bc;
    this.description = event.description;
    this.displayedQuestions = event.rounds[round].displayedQuestions;
    this.endDate = event.endDate;
    this.image = event.image;
    this.is2FA = event.is2FA;
    this.isFinished = event.isFinished;
    this.isPublicVote = event.publicVote;
    this.name = event.name;
    this.numberOfVote = event.numberOfVote;
    this.public_key = event.rounds[round].public_key;
    this.questions = event.rounds[round].questions;
    this.rounds = event.rounds.length;
    this.sharedLinks = event.sharedLinks;
    this.shareResults = event.shareResults;
    this.short_name = event.short_name;
    this.startDate = event.startDate;
    this.type = event.type;
    this.uuid = event.uuid;
    this.voters = event.voters;
    this.votes = event.votes;
    this.isLive = event.isLive;
    this.forceBlankVote = event.forceBlankVote;
    this.round = round;
    this.displayedCandidates = Number.parseInt(event.rounds[round].displayedCandidates, 10);
  }
}

export class PollPreview {
    bc: boolean;
    description: string;
    endDate: string;
    is2FA: boolean;
    isFinished: boolean;
    isPublicVote: boolean;
    name: string;
    shareResults: boolean;
    sharedLinks: string;
    startDate: string;
    type: string;
    voters: number;
    votes: number;
    isLive: boolean;
    assessors: number;
    validatedAssessors: number;
    opened: boolean;
    isTallied: boolean;
    deleted: boolean;
}

class BackendDate {
  afterNow: boolean;
  beforeNow: boolean;
  millis: number;
}

export class Choice {
  id: string;
  text: string;
  link: string;
  image: string;
  image_id: number;
  constructor(text: string, link: string = "", image = "") {
    this.id = '';
    this.text = text;
    this.image_id = 1;
    this.link = link
    this.image = image
  }
}

export class ParticipationResponse {
  votes: number;
  voters: number;
}

export class ReceivedQuestion {
  question: string;
  answers: Array<string>;
  numberOfVote: number;
  id: number;
  parent: number;
  links: Array<string>;
  listLink: Array<string>;
  listImages: Array<string>;
  minNumberOfVote: number;
}


export class ChoiceSelection {
  choice: Choice;
  choiceIndex: number;
  roundIndex: number;
  constructor(choice: Choice, choiceIndex: number, roundIndex: number) {
    this.choice = choice;
    this.choiceIndex = choiceIndex;
    this.roundIndex = roundIndex;
  }
}

export class ChoiceUnselection {
  choiceIndex: number;
  roundIndex: number;
  constructor(choiceIndex: number, roundIndex: number) {
    this.choiceIndex = choiceIndex;
    this.roundIndex = roundIndex;
  }
}

export class ResolutionResult {
  question: string;
  answers: string[];
  results: Result[];
  constructor(question: string, answers: string[], results: Result[]) {
    this.question = question;
    this.answers = answers;
    this.results = results;
  }
}

export class ListResult {
  question: string;
  answers: string[];
  results: Result[];
  index: number;
  parent: number;
  votes: number;
  constructor(question: string, answers: string[], results: Result[], index: number, parent: number, votes: number) {
    this.question = question;
    this.answers = answers;
    this.results = results;
    this.index = index;
    this.parent = parent;
    this.votes = votes;
  }
}

export class Result {
  constructor(
    public text: string,
    public percentage: number,
    public votes: number
  ) {}
}

export class CodeResponse {
  public url: string;
  public code: string;
  constructor(url: string, code: string) {
    this.url = url;
    this.code = code;
  }
}

export class AssessorInfo {
  public name: string;
  public used: boolean;
  constructor(name: string, used: boolean) {
    this.name = name;
    this.used = used;
  }
}

export class AssessorParticipationResponse {
  public assessors: number;
  public validated: number;
  constructor(assessors: number, validated: number) {
    this.assessors = assessors;
    this.validated = validated;
  }
}

export class PollQuestion {
  public question: string;
  public answers: Array<string>;
  public numberOfVote: number;

  constructor(question: string, answers: Array<string>, numberOfVote: number) {
    this.answers = answers;
    this.question = question;
    this.numberOfVote = numberOfVote;
  }
}

export class Resolution {
  text: string;
  description: string;
  choices: Choice[];
  links: Array<string>;
  constructor(text: string, description: string, choices: Choice[], links: Array<string> = []) {
    this.text = text;
    this.description = description;
    this.choices = choices;
    this.links = links
  }
}

export class KeyGroup {
  pairing_parameters_name: string;
  g0: string;
  g: string;
  gT: string;
  gtild: string;
  gtildT: string;
  hashAlgo: string;
}

export class PublicKey {
  gs: string;
  th: string;
  htildT: string;
  group: KeyGroup;
}

export class Ballot {

}

export interface PollAccess {
  id: string;
  code: number;
}
