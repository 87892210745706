declare var levote_JSONSerialObj: any;
declare var levote_JSONSerial: any;
declare var levote_Voter: any;
declare var levote_VoterPrivateKeyObj: any;

import { SHA256, enc } from 'crypto-js';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Ballot,
  Choice,
  Resolution,
  ChoiceSelection,
  ReceivedQuestion,
  VotingEvent,
  Poll,
  PollStatus,
  ChoiceUnselection, ParticipationResponse
} from '../shared/model/poll.model';
import { Common } from '../shared/common';
import * as interfaceEnums from './interfaceEnums';
import { ErrorList } from './interfaceEnums';
import { ApiService } from '../shared/api.service';
import { LiveService } from '../shared/live.service';
import { WebsocketService } from '../shared/websocket.service';
import { environment } from '../../environments/environment';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from 'src/app/firebase';

@Component({
  selector: 'app-detail-choice',
  templateUrl: 'poll-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./poll-detail.component.less', '../shared/common.less'],
  providers: [WebsocketService, LiveService],
})

export class PollDetailComponent implements OnInit {
  isValidated = true;
  validateVotePopup = false;
  pollId: string;
  electionDataJson: VotingEvent = null;
  activePoll: Poll;
  isMobile: boolean;
  public isVotingAnimation = false;
  isResolution = false;
  privateKey: string;
  votingProof: string;
  selectedResolution: Resolution;
  titleList: string[] = [];
  choices: Choice[][] = [];
  cryptoChoices: Choice[][] = [];
  resolutions: Array<Resolution> = [];
  liveVoteKey: string;
  responses: number[][] = [];
  participation: number;
  dateString: string;
  public isError = false;
  public isLive = false;
  selectedChoice: Choice = null;
  indexes: Array<Array<number>> = [];
  validPlurinominal = true;
  // Current round
  liveActiveResolution = 0;
  status: PollStatus = PollStatus.list;
  statuses = PollStatus;
  url = 'assets/placeholder.png';
  errorMessage: string;
  errorTitle: string;
  errorCanRetry: boolean;
  votingType: string;
  numberOfVote: Array<number> = [1];
  plurinominalWarning = '';
  validationApiBlocker = false;
  isValidationCodeError = false;
  noMoreSMS = false;
  userPhoneNumber: any;
  userRetriesRemaining: number;
  answeredCount = 0;
  websocketConnected = false;
  forceRefreshVotedMessage = false;
  blockchainUrl: string;
  voteHash: any;
  validation: string;
  votedStatuses: Array<boolean> = [];
  resolutionImages: Array<string> = [];
  resolutionImagesDownloaded: Array<any> = [];
  liveSubjectLink = '';

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private liveService: LiveService,
    public common: Common
  ) {}

  resendSMS() {
    this.apiService.getSMS(this.pollId, this.privateKey).subscribe((res) => {
      this.userRetriesRemaining--;
    },
    (err: any) => {
      console.log(err);
      this.noMoreSMS = true;
    });
  }

  validate($event: string) {
    this.isValidationCodeError = false;
    this.apiService.validateVoter(this.pollId, this.privateKey, $event).subscribe((res) => {
      this.isValidated = true;
    },
    (err: any) => {
      this.isValidationCodeError = true;
    });
  }

  plurinominalSelection(event: ChoiceSelection) {
    if (this.indexes[event.roundIndex].includes(event.choiceIndex)) {
      const circle = document.getElementById('circle-' + event.choiceIndex) as HTMLElement;
      if (circle !== null) { circle.style.backgroundImage = 'url(../../../assets/circle.svg)'; }
      this.indexes[event.roundIndex] = this.indexes[event.roundIndex].filter((value, index, arr) => value !== event.choiceIndex);
    } else {
      this.indexes[event.roundIndex].push(event.choiceIndex);
    }
    if (this.isForcedBlankVote()) {
      this.handleForceBlankVote(event);
    }
    this.validPlurinominal = this.numberOfVote[event.roundIndex] > this.indexes[event.roundIndex].length;
  }

  handleForceBlankVote(event: ChoiceSelection) {
    this.selection(event);
    this.indexes[event.roundIndex] = [event.choiceIndex];
  }

  selection($event: ChoiceSelection) {
    if (this.indexes[$event.roundIndex] != null && this.indexes[$event.roundIndex].length > 0) {
      const check = document.getElementById('checkbox-' + this.indexes[$event.roundIndex][0]);
      if (check !== null) { check.click(); }
    }
    if (this.indexes[$event.roundIndex] == null) { this.indexes[$event.roundIndex] = []; }
    if (this.indexes[$event.roundIndex].includes($event.choiceIndex)) {
      const circle = document.getElementById('circle-' + $event.choiceIndex) as HTMLElement;
      if (!(this.choices[$event.roundIndex][$event.choiceIndex].image !== undefined &&
        this.choices[$event.roundIndex][$event.choiceIndex].image !== 'null' &&
        this.choices[$event.roundIndex][$event.choiceIndex].image !== null &&
        this.choices[$event.roundIndex][$event.choiceIndex].image.length > 0)) {
        circle.style.backgroundImage = 'url(../../../assets/circle.svg)';
      }
      this.indexes[$event.roundIndex] = this.indexes[$event.roundIndex].filter((value, index, arr) => value !== $event.choiceIndex);
    } else {
      this.indexes[$event.roundIndex].push($event.choiceIndex);
    }
  }


  plurinominalUnselection($event: ChoiceUnselection) {
    if (this.indexes[$event.roundIndex].includes($event.choiceIndex)) {
      this.indexes[$event.roundIndex] = this.indexes[$event.roundIndex].filter((value, index, arr) => value !== $event.choiceIndex);
    } else {
        // Get the snackbar DIV
        const x = document.getElementById('snackbar');
        // Add the "show" class to DIV
        x.className = 'show';
        // After 3 seconds, remove the show class from DIV
        setTimeout(() => {
            x.className = x.className.replace('show', '');
          }, 3000);
    }
    this.validPlurinominal = !(this.numberOfVote[$event.roundIndex] <= this.indexes[$event.roundIndex].length);
  }

  eventSelection($event: number) {
    this.setPollRound($event);
    this.setupQuestionLists($event);
    this.route.queryParams.subscribe((params) => {
      if ($event === 0) {
        this.privateKey = params.key;
      } else {
        const paramName = 'round' + $event;
        this.privateKey = params[paramName];
      }
    });
    this.status = PollStatus.listVote;
  }

  retry() {
    this.indexes = [];
    this.validPlurinominal = true;
    this.status = PollStatus.list;
    this.liveService.send('status', this.pollId, this.privateKey);
    this.liveService.send('key', this.pollId, this.privateKey);
  }

  resolutionChoicePreselected(event: Choice) {
    this.selectedChoice = event;
    this.status = PollStatus.voteResolution;
    window.scrollTo(0, 0);
  }

  resolutionPreselected(event: Resolution) {
    this.status = PollStatus.resolutonDetails;
    this.selectedResolution = event;
  }

  choiceConfirmed() {
    this.status = PollStatus.vote;
  }

  choiceConfirmedResolution() {
    this.status = PollStatus.voteResolution;
  }

  choiceVote(event: string) {
    if (this.votingType === 'resolution' && !this.isLive && !this.isAllAnswered()) {
      this.status = PollStatus.resolutionWarning;
    } else {
      event === 'voted' ? this.votingPopup() : (this.status = PollStatus.list);
    }
  }

  handleWarning(event: boolean) {
    event ? this.actionVote() : this.status = PollStatus.validatedList;
  }

  isAllAnswered(): boolean {
    if (this.votingType !== 'resolution') {
      return true;
    } else {
      return this.responses.filter(a => a[3] === 0).length === this.resolutions.length;
    }
  }

  actionBack() {
    if (this.status === PollStatus.vote) { this.status = PollStatus.validation; }
    else {
      if ((this.status === PollStatus.resolutonDetails || this.status === PollStatus.voteResolution) && this.isPartiallyAnswered()) {
        this.status = PollStatus.validatedList;
      }
      else {
        if (this.status === PollStatus.multielectionRoundSelected) { this.updateAnsweredRoundCount(); }
        else {
          if (this.status === PollStatus.listSelected) {
            this.liveActiveResolution = 0;
            this.status = PollStatus.listVote;
          }
          else {
            if (this.status === PollStatus.listVote || (this.status === PollStatus.voted && this.isMultiCorporate())) {
                this.getVotedStatus();
                this.status = PollStatus.eventList;
              }
            else { this.status = PollStatus.list; }
          }
        }
      }
    }
  }

  isPartiallyAnswered(): boolean {
    if (this.votingType !== 'resolution' && this.votingType !== 'live') {
      return true;
    } else {
      return this.responses.filter(a => a[3] === 0).length > 0;
    }
  }

  dismissError() {
    this.isError = false;
  }

  setResolutionChoice($event: string) {
    if ($event === 'cancel') {
      this.actionBack();
    } else {
      const resIndex = this.resolutions.indexOf(this.selectedResolution);
      this.responses[resIndex] = this.resolutions[resIndex].choices.map(a => this.selectedChoice === a ? 1 : 0);
      this.status = PollStatus.list;
      if (this.responses.length > 0) {
        for (const i in this.resolutions) {
          if (this.responses[i] == null) {
            this.responses[i] = [0, 0, 0, 1];
          }
        }
        if (!this.isLive && !this.isAllAnswered()  && this.resolutions.length > 1) {
          this.status = PollStatus.resolutonDetails;
          this.selectedResolution = this.resolutions[this.getNextResolution(resIndex)];
        } else {
          this.status = PollStatus.validatedList;
          if (this.responses.length > 1 && !this.isLive) {
            setTimeout(() => {
              const parent = document.getElementsByClassName('video-included-content')[0];
              if (parent && parent.scrollHeight > window.innerHeight) {
                const element = document.getElementById(`wrapper-${resIndex}`);
                element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
              }
            }, 100);
          }
        }
      }
    }
  }

  getNextResolution(index: number): number {
    const mappedResponses = this.responses.map((value, key) => [value, key]);
    const indexedUnansweredResolution = mappedResponses.filter(a => (a[0] as Array<number>).indexOf(1) === 3);
    const unansweredIndexes = indexedUnansweredResolution.map(a => a[1] as number);
    const greaterIndexes = unansweredIndexes.filter(a => a < index);
    const lowerIndexes = unansweredIndexes.filter(a => a > index );
    if (lowerIndexes.length > 0) {
      return lowerIndexes[0];
    } else {
       if (greaterIndexes.length > 0) { return greaterIndexes[0]; }
       return 0;
    }
  }

  votingPopup() {
    this.validateVotePopup = true;
  }

  validateVote($event: boolean) {
    if ($event === true) {
      this.validateVotePopup = false;
      this.actionVote();
    } else {
      this.validateVotePopup = false;

    }
  }

  hasForcedBlankVote() {
    return this.electionDataJson.forceBlankVote && (this.activePoll.displayedQuestions.length === (this.liveActiveResolution + 1));
  }

  isForcedBlankVote() {
    return this.electionDataJson.forceBlankVote && this.indexes[this.liveActiveResolution].indexOf(this.activePoll.displayedQuestions[this.liveActiveResolution].answers.length - 1) !== -1;
  }

  isForcedBlankVoteAtIndex(index: string) {
    return this.electionDataJson.forceBlankVote && this.indexes[index].indexOf(this.activePoll.displayedQuestions[index].answers.length - 1) !== -1;
  }

  minVoteLimitNotAchieved() {
    if (!this.isLive && this.activePoll.displayedQuestions[this.liveActiveResolution].minNumberOfVote != null) {
      const limitCondition = this.activePoll.displayedQuestions[this.liveActiveResolution].minNumberOfVote > this.indexes[this.liveActiveResolution].length;
      return limitCondition && !this.isForcedBlankVote();
    }
    if (this.isLive && this.activePoll.displayedQuestions[0].minNumberOfVote != null) {
      return this.activePoll.displayedQuestions[0].minNumberOfVote > this.indexes[this.liveActiveResolution].length;
    }
    return false;
  }

  getMinimButtonCounter() {
    if (!this.isLive) { return this.activePoll.displayedQuestions[this.liveActiveResolution].minNumberOfVote; }
    else { return this.activePoll.displayedQuestions[0].minNumberOfVote; }
  }

  validatePlurinominal() {
    if (this.indexes[this.liveActiveResolution].length < this.numberOfVote[this.liveActiveResolution] && !this.isForcedBlankVote()) {
      this.plurinominalWarning = 'incomplete';
    } else {
      this.status !== PollStatus.multielectionRoundSelected ? this.votingPopup() : this.electionRoundVote();
    }
  }

  validateMultielection() {
    if (this.activePoll.displayedQuestions.length > this.answeredCount) { this.plurinominalWarning = 'multiElection'; }
    else { this.votingPopup(); }
  }

  handlePopupReturn($event: boolean) {
    if ($event) {
      this.status !== PollStatus.multielectionRoundSelected ? this.actionVote() : this.electionRoundVote();
      this.plurinominalWarning = '';
    } else {
      this.plurinominalWarning = 'cancelled';
    }
  }

  roundCanBeAnswered(): boolean {
    return  !(this.numberOfVote[this.liveActiveResolution] <= this.indexes[this.liveActiveResolution].length);
  }

  setActiveElection($event: number) {
    this.liveActiveResolution = $event;
    this.validPlurinominal = this.roundCanBeAnswered() || this.numberOfVote[this.liveActiveResolution] === 1;
    this.status = PollStatus.multielectionRoundSelected;
    const body = document.getElementsByTagName('body');
    this.choices[this.liveActiveResolution].length >= 5 ? body[0].style.paddingBottom = '100px' : body[0].style.paddingBottom = '0';
  }

  setActiveList($event: number) {
    const previousVoteBlancValue = this.indexes[0].reverse()[0];
    const children = this.activePoll.displayedQuestions.map((value, idx) => ({posKey: value.parent === $event, iKey: idx}));
    this.indexes = this.choices.map((round, index) => round.map(choice =>
      (choice.text === 'oui' && children[index].posKey) || (choice.text === 'non' && !children[index].posKey) ? 1 : 0));
    this.indexes[0][$event] = 1;
    if ($event !== this.choices[0].length - 1) {
      if ($event === this.getSelectedList()) {
        this.indexes = JSON.parse(JSON.stringify(this.responses));
      }
      this.status = PollStatus.listSelected;
      this.liveActiveResolution = $event;
    } else {
      if (previousVoteBlancValue === 1) {
        this.indexes[0] = this.indexes[0].map(x => 0);
      }
      this.responses =  JSON.parse(JSON.stringify(this.indexes));
    }
  }

  getOtherCandidateCount() {
    return this.activePoll.displayedQuestions.filter( x => x.parent < this.liveActiveResolution && x.parent !== null).map((p, index) => {}).length
  }

  getListItems(): Array<ReceivedQuestion> {
    const counter = this.getOtherCandidateCount();
    this.activePoll.displayedQuestions.filter( x => x.parent === this.liveActiveResolution).map((p, index) => {
        p.links = [this.activePoll.displayedQuestions[0].links[counter + index]];
    });
    return this.activePoll.displayedQuestions.filter( x => x.parent === this.liveActiveResolution);
  }

  getAnswersForList(): Array<number> {
    const reqIndexes = this.indexes.filter((_, idx) => this.activePoll.displayedQuestions[idx].parent === this.liveActiveResolution);
    return reqIndexes.map(x => x[0]);
  }

  setListAnswered($event: Array<number>) {
    const ansQuest = this.activePoll.displayedQuestions.map((x, index) => ({val: x.parent === this.liveActiveResolution, i: index}));
    const fillIndexes = ansQuest.filter(x => x.val === true).map(y => y.i);
    fillIndexes.forEach((value, index) => {
      this.indexes[value] = $event[index] === 1 ? [1, 0] : [0, 1];
    });
    this.responses =  JSON.parse(JSON.stringify(this.indexes));
    this.status = PollStatus.listVote;
    this.liveActiveResolution = 0;
  }

  sendListCancel() {
    this.indexes = JSON.parse(JSON.stringify(this.responses));
    this.status = PollStatus.listVote;
    this.liveActiveResolution = 0;
  }

  createCryptoResponse(cryptedPoll: Poll, key: string, response: number[][]): any {
    let electionData: any;
    try {
      electionData = levote_JSONSerialObj.electionFromJSON(
        JSON.stringify(cryptedPoll)
      );
    } catch (e) {
      this.getVotingError('retry');
    }

    const params = electionData.getParams();
    const jsonSerial = new levote_JSONSerial(params);
    const voterPrivKey = levote_VoterPrivateKeyObj.fromString(
      key,
      10
    );
    const voter0 = new levote_Voter(voterPrivKey, electionData, true);
    const ballot = voter0.generateBallot(response);
    const ballotJson = jsonSerial.toJSON(ballot);
    const commit = ballot.getBallotCommitment();
    const ballotCommitJson = jsonSerial.toJSON(commit);
    console.log(ballotCommitJson);
    return ballotJson;
  }

  resetResponseIndexes() {
    for (const round in this.choices) {
      if (this.choices.hasOwnProperty(round)) {
        if (this.status !== PollStatus.validatedList && this.status !== PollStatus.resolutionWarning) {
          this.responses[round] = [];
        }
        if (this.activePoll.type === 'election' && !this.isLive && this.activePoll.displayedQuestions[round].minNumberOfVote != null) {
          if (this.indexes[round] != null && this.activePoll.displayedQuestions[round].minNumberOfVote > this.indexes[round].length && !this.isForcedBlankVoteAtIndex(round)) {
            this.indexes[round] = null;
          }
        }
        if (this.activePoll.type === 'election' && this.isLive && this.activePoll.displayedQuestions[0].minNumberOfVote != null) {
          if (this.indexes[round] != null && this.activePoll.displayedQuestions[0].minNumberOfVote > this.indexes[round].length) {
            this.indexes[round] = null;
          }
        }
        for (const i in this.choices[round]) {
          if (this.choices[round].hasOwnProperty(i)) {
            if (this.indexes[round] == null) { this.indexes[round] = []; }
            this.indexes[round].includes(parseInt(i, 10)) ? this.responses[round].push(1) : this.responses[round].push(0);
          }
        }
      }
    }
  }

  actionVote() {
    if (this.electionDataJson.forceBlankVote) {
      this.choices.forEach((value, index) => {
        if (this.indexes[index].indexOf(value.length - 1) !== -1 && this.indexes[index].length > 1) {
          this.createBlankVoteError();
          return;
        }
      });
    }
    this.isVotingAnimation = true;
    if (this.electionDataJson.type !== 'list') { this.resetResponseIndexes(); }
    if (!this.isLive) {
      let finalVote: Ballot;
      if (!this.electionDataJson.bc) {
        const uncryptedResponse = {
          pubkey: this.privateKey,
          ballot: this.responses,
        };
        finalVote = JSON.stringify(uncryptedResponse);
      } else {
        this.activePoll.displayedQuestions.forEach((value, index) => {
          if (value.numberOfVote > 1) {
            this.encodePlurinominalRound(index);
          }
        });
        finalVote = this.createCryptoResponse(this.activePoll, this.privateKey, this.responses);
      }
      this.postVote(finalVote);
    } else {
      // Entre dans le else lors d'un vote live
      let voteMessage = '';
      if (!this.activePoll.bc) {
        const responseIndex = this.isResolution ? 0 : this.liveActiveResolution;
        const currentRoundVote = this.responses[responseIndex];
        const responseCoding = JSON.stringify([currentRoundVote]);
        voteMessage = 'vote ' + responseCoding;
      } else {
        const editablePoll = new Poll(this.electionDataJson, this.liveActiveResolution);
        if (this.numberOfVote[this.liveActiveResolution] > 1) {
          this.encodePlurinominalRound(this.liveActiveResolution);
        }
        const responseIndex = this.isResolution ? 0 : this.liveActiveResolution;
        voteMessage = 'vote ' + this.createCryptoResponse(editablePoll, this.liveVoteKey, [this.responses[responseIndex]]);
      }
      this.liveService.send(voteMessage, this.pollId, this.privateKey);
    }
  }

  electionRoundVote() {
    this.updateAnsweredRoundCount();
    this.candidateProposalNext();
  }

  candidateProposalNext() {
    if (this.answeredCount < this.indexes.length) {
      const mappedIndexes = this.indexes.map((value, index) => [index, value]);
      const filteredIndexes = mappedIndexes.filter(elem => (elem[1] as Array<number>).length === 0);
      const indexedIndexes = filteredIndexes.map(x => x[0] as number);
      const upperIndexes = indexedIndexes.filter(x => x > this.liveActiveResolution);
      const lowerIndexes = indexedIndexes.filter(x => x < this.liveActiveResolution);
      if (upperIndexes.length > 0) {
        this.setActiveElection(upperIndexes[0]);
      } else if (lowerIndexes.length > 0) {
        this.setActiveElection(lowerIndexes[0]);
      }
    }
  }

  updateAnsweredRoundCount() {
    if (this.activePoll.type !== 'election') {
      this.answeredCount = this.indexes.filter(x => x.length > 0).length;
    } else {
      const minimums = this.activePoll.displayedQuestions.map(item => item.minNumberOfVote);
      this.answeredCount = 0;
      for (const i in this.indexes) {
        if (this.indexes[i].length >= minimums[i] || this.isForcedBlankVoteAtIndex(i)) {
          this.answeredCount++;
        }
      }
    }
    const body = document.getElementsByTagName('body');
    this.activePoll.displayedQuestions.length >= 5 ? body[0].style.paddingBottom = '100px' : body[0].style.paddingBottom = '0';
    this.status = PollStatus.electionsList;
  }

  connectToWs() {
    this.isLive = true;
    this.liveService.connect(this.pollId, this.privateKey).subscribe((msg) => {
      console.log('Response from websocket: ' + msg);
      this.websocketConnected = true;
      // this.getPollData()
      const message = msg.split(' ')[1];

      if (message.charAt(0) === '[') {
        const liveStatus = JSON.parse(message);
        if (
          liveStatus.findIndex((status: number) => status === 1) !==
          this.liveActiveResolution
        ) {
          this.refreshPoll();
          const previousActiveResolution = this.liveActiveResolution;
          this.liveActiveResolution = liveStatus.findIndex(
            (status: number) => status === 1
          );
          this.indexes[this.liveActiveResolution] = [];
          this.forceRefreshVotedMessage = previousActiveResolution !== this.liveActiveResolution;
        }
      }
      if (msg.split(' ')[1] === 'key') {
        this.liveVoteKey = msg.split(' ')[2];

        if (this.liveActiveResolution !== -1) { this.getVotedStatus(); }
      }
      if (msg.split(' ')[1] === 'validator') {
        this.validation = msg.split(' ')[2];
      }
      if (msg.split(' ')[0] === '200' && msg.split(' ')[1] === 'voted' && this.isVotingAnimation === true) {
        this.setVoteSuccessMessage();
      }
      if (msg.split(' ')[0] === '400') {
        this.status = PollStatus.voteFailed;
        if (msg.substring(4) === 'Bad Request Election is not active') {
          this.getVotingError('closed');
        } else {
          this.createAlreadyVotedError();
        }
        this.isVotingAnimation = false;
        this.isError = true;
      }
      if (msg.split(' ')[0] === '404' && msg.substring(4) !== 'Not Found No active round for election') {
        this.status = PollStatus.voteFailed;
        this.getVotingError('retry');
        this.isVotingAnimation = false;
        this.isError = true;
      }
    });
    this.liveService.send('status', this.pollId, this.privateKey);
    this.liveService.send('key', this.pollId, this.privateKey);
    const timer = setInterval(() => {
      this.liveService.send('status', this.pollId, this.privateKey);
      this.liveService.send('key', this.pollId, this.privateKey);
    }, 30000);
  }

  getSelectedList(): number {
    return this.responses[0].findIndex(x => x === 1);
  }

  encodePlurinominalRound(questionIndex: number) {
    const cryptoResponse = this.responses[questionIndex].join('');
    const responseIndex = this.cryptoChoices[questionIndex].findIndex(
      (x) => x.text === cryptoResponse
     );
    this.responses[questionIndex] = new Array(this.cryptoChoices[questionIndex].length).fill(0);
    this.responses[questionIndex][responseIndex] = 1;
  }
  refreshParticipation(round: number) {
    this.apiService.getPoll(this.pollId).subscribe((res: VotingEvent) => {
    this.electionDataJson = res;
    this.setUpParticipation(round);
    });
  }

  hasDispalyedQuestionField(): boolean {
    return this.activePoll.displayedQuestions.length === this.activePoll.questions.length;
  }

  isCryptoCandidates(): boolean { return this.electionDataJson.bc && !this.isLive && this.electionDataJson.type === 'election'; }

  postVote(ballot: any) {
    this.apiService.createBallot(this.pollId, ballot, this.validation, this.activePoll.round).subscribe(
      (res) => {
        this.votingProof = JSON.stringify(res);
        this.setVoteSuccessMessage();
        this.getVotedStatus();
      },
      (err: any) => {
        this.status = PollStatus.voteFailed;
        if (err.status === 400) {
          if (err.error.error === 'Election is closed') {
            this.getVotingError('closed');
          }
          else if (err.error.error === 'Voter has already voted') {
            this.createAlreadyVotedError();
          } else {
                if (err.error.error ===  'Invalid vote (blank vote + other answers)') {
                  this.createBlankVoteError();
                } else {
                    this.getVotingError('retry');
                }
             }
          } else {
             this.getVotingError('retry');
            }
        this.isVotingAnimation = false;
        this.isError = true;
      }
    );
  }

  sendVoteConfirmationByMail(hash: string) {
    this.apiService.sendVoteConfirmation(this.pollId, hash, this.privateKey, this.activePoll.round).subscribe((res) => {
      console.log(res);
    }, (err) => {
      console.log(err);
    });
  }

  getHeaderTitle(): string {
    if (this.electionDataJson.type === 'list' && this.electionDataJson.rounds.length > 1 && this.status !== PollStatus.eventList) { return this.electionDataJson.rounds[this.activePoll.round].title; }
    if (this.electionDataJson !== null) { return this.electionDataJson.name; }
    return '';
  }

  setVoteSuccessMessage() {
    this.isVotingAnimation = false;
    this.status = PollStatus.voted;
    this.refreshParticipation(this.activePoll.round);
    if (!this.isLive) {
      this.getVoteHash();
    }
  }

  getVoteHash() {
      this.voteHash = this.sha(this.votingProof);
      this.sendVoteConfirmationByMail(this.voteHash);
  }

  getVotingError(reason: string, optional: string = null) {
    let messageList: ErrorList;
    switch (this.getMessageDisplayLanguage()) {
      case 'fr': {
        messageList = interfaceEnums.votingErrors.fr;
        break;
      }
      case 'es': {
        messageList = interfaceEnums.votingErrors.es;
        break;
      }
      default: messageList = interfaceEnums.votingErrors.en;
    }
    switch (reason) {
      case 'closed': {
        this.errorTitle = messageList.closed.title;
        this.errorCanRetry = messageList.closed.canRetry;
        this.errorMessage = messageList.closed.description;
        break;
      }
      case 'retry': {
        this.errorTitle = messageList.retry.title;
        this.errorCanRetry = messageList.retry.canRetry;
        this.errorMessage = messageList.retry.description;
        break;
      }
      case 'blankVote': {
        this.errorTitle = messageList.blankVote.title;
        this.errorCanRetry = messageList.blankVote.canRetry;
        this.errorMessage = messageList.blankVote.description;
        break;
      }
      case 'voted': {
        this.errorTitle = messageList.voted.title;
        this.errorCanRetry = messageList.voted.canRetry;
        this.errorMessage = optional = null ? messageList.voted.description : messageList.votedAt.description + optional;
        break;
      }
    }
  }

  getListCompletion(): boolean {
    const responsesList = this.responses.map(answer => answer.filter(x => x === 1).length === 1);
    return responsesList.indexOf(false) === -1;
  }

  setupQuestionLists(selectedElection = 0) {
    if (!this.isResolution) {
      this.activePoll.displayedQuestions = this.hasDispalyedQuestionField() ?
        this.activePoll.displayedQuestions : this.activePoll.questions;
      this.indexes = this.isLive ? this.electionDataJson.rounds.map(r => []) : this.activePoll.displayedQuestions.map(a => []);
      this.titleList = this.isLive ? this.electionDataJson.rounds.map(r => r.displayedQuestions.map(q => q.question)).flat() : this.activePoll.displayedQuestions.map(a => a.question);
      this.numberOfVote = this.isLive ? this.electionDataJson.rounds.map(r => r.displayedQuestions.map(q => q.numberOfVote)).flat() : this.activePoll.displayedQuestions.map(a => a.numberOfVote);
      this.choices = this.isLive ?
        this.electionDataJson.rounds.map(r => r.displayedQuestions.map(q => q.answers.map((a, index) => new Choice(a, q.links[index + 1], q.listImages[index]) ))).flat() :
        this.activePoll.displayedQuestions.map(a => a.answers.map((b, index) => new Choice(b, a.links[index + 1], a.listImages[index])));
      // tslint:disable-next-line:triple-equals
      if (this.activePoll.type === 'election' && (this.activePoll.isLive || this.activePoll.displayedQuestions.length === 1)) {
        this.liveSubjectLink = this.activePoll.displayedQuestions[0].links[0];
      }
      if (this.activePoll.type === 'election') {
        for (let index = 0; index < this.choices.length; index++) {
          for (let imageIndex = 0; imageIndex < this.choices[index].length; imageIndex++) {
            if (this.choices[index][imageIndex].image !== undefined &&
              this.choices[index][imageIndex].image !== null &&
              this.choices[index][imageIndex].image != 'null' &&
              this.choices[index][imageIndex].image.length > 0)
              getDownloadURL(ref(storage, this.choices[index][imageIndex].image)).then((url) => {
                this.choices[index][imageIndex].image = url;
                this.choices = Object.assign([], this.choices);
              });
          }
        }
      }

      if (this.activePoll.type === 'list') {
        this.choices = this.activePoll.displayedQuestions.map(a => a.answers.map((b, index) =>
          new Choice(b, this.electionDataJson.rounds[selectedElection].displayedQuestions[0].listLink[index], a.listImages[index])
        ))

        this.choices.forEach((element, index) => {
          element.forEach((answer, imageIndex) => {
            if (answer.image !== undefined && answer.image.length > 0 && answer.image != 'null')
              getDownloadURL(ref(storage, answer.image)).then((url) => {
                answer.image = url;
                this.activePoll.displayedQuestions[index].listImages[imageIndex] = url;
              });
          });
        });
      }

      this.cryptoChoices = this.isLive ? this.electionDataJson.rounds.map(r => r.questions.map(q => q.answers.map(a => new Choice(a)))).flat() : this.activePoll.questions.map(a => a.answers.map(b => new Choice(b)));
      if (this.electionDataJson.type === 'liveCandidates' || (this.electionDataJson.type === 'election') && this.isLive) {
        if (!this.websocketConnected) { this.connectToWs(); }
          } else {
            if (this.electionDataJson.type !== 'list' ) {
              if (this.choices.length > 1) { this.updateAnsweredRoundCount(); }
            } else {
              this.responses = this.activePoll.displayedQuestions.map(a => []);
              this.liveActiveResolution = 0;
              if (this.status !== PollStatus.voteFailed) { this.status = PollStatus.listVote; }
            }
      }
    } else {
      this.resolutions = this.activePoll.displayedQuestions.map(a => new Resolution(a.question, '', a.answers.map(b => new Choice(b)), a.links));
      if ((this.electionDataJson.type === 'live' || this.electionDataJson.type === 'resolution') && this.isLive && !this.websocketConnected) {
        this.connectToWs();
      }
    }

  }

  getPollData() {
    this.apiService.getPoll(this.pollId).subscribe((res: VotingEvent) => {
      this.electionDataJson = res;
      if (this.electionDataJson.isLive) {
        this.liveActiveResolution = this.electionDataJson.rounds.findIndex(r => r.opened === true);
      }
      this.setPollRound(this.liveActiveResolution);
      if (this.liveActiveResolution != -1 && res.rounds[this.liveActiveResolution].displayedQuestions[0].listImages[0] != "null"
          && res.rounds[this.liveActiveResolution].displayedQuestions[0].listImages[0] != undefined) {
            if (res.rounds[this.liveActiveResolution].results == null && res.type === 'live') {
              this.resolutionImages = res.rounds.map(round => round.displayedQuestions[0].listImages[0]);
            }
            if (res.type === 'resolution') {
              this.resolutionImages = res.rounds[this.liveActiveResolution].displayedQuestions.map(question => question.listImages[0]);
            }
      }
      if (this.resolutionImages != null && this.resolutionImages.length > 0) {
        if (res.isLive) {
          if (this.resolutionImagesDownloaded[this.liveActiveResolution] == undefined)
            getDownloadURL(ref(storage, this.resolutionImages[this.liveActiveResolution])).then((url) => {
              this.resolutionImagesDownloaded[this.liveActiveResolution] = url;
              this.resolutionImagesDownloaded = Object.assign([], this.resolutionImagesDownloaded);
            });
        } else {
          this.resolutionImages.forEach((elem, index) => {
            if (elem != undefined && elem != 'null' && elem.length > 0)
            getDownloadURL(ref(storage, elem)).then((url) => {
              this.resolutionImagesDownloaded[index] = url;
              this.resolutionImagesDownloaded = Object.assign([], this.resolutionImagesDownloaded);
            });
          });
        }
      }


      this.isResolution = res.type === 'resolution' || res.type === 'live';
      this.numberOfVote = [];
      this.isLive = res.isLive;
      this.setupQuestionLists();
      this.participation = Math.round(
        (this.electionDataJson.votes / this.electionDataJson.voters) * 100
      );
      const endDate = res.endDate;
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      this.dateString = new Date(endDate).toLocaleDateString(navigator.language, options);
      this.votingType = res.type;
      if (!this.validationApiBlocker && this.electionDataJson.is2FA) {
        this.validationApiBlocker = true;
        this.setup2FA();
      }

      if ((this.isLive && this.choices.length > 0 && this.choices[0].length > 5) ||
      (!this.isLive && this.resolutions.length > 5) ||
      (this.activePoll.questions[0].answers.length > 5)){
        const wrapper = document.getElementById('content-wrap');
        wrapper.style.paddingBottom = '100px';
      }
      if (this.electionDataJson.rounds.length > 1 && !this.isLive) {
        this.status = PollStatus.eventList;
      }
      if (res.isFinished) {
        if (res.shareResults) {
          window.location.href = window.location.origin + '/poll/' + this.pollId + '/results';
        }
        else { this.status = PollStatus.completed; }
        }
      if (res.opened) { this.getVotedStatus(); }
      if (res.image.length > 0) {
        getDownloadURL(ref(storage, res.image)).then((url) => {
          const logo = document.getElementById('v8te-logo') as HTMLImageElement;
          const link = document.getElementById('v8te-url-image') as HTMLElement;
          link.removeAttribute('href');
          logo.src = url;
          logo.style.width = '200px';
          logo.style.maxHeight = '200px';
          logo.style.marginTop = '24px';
        });
      }
    });
  }

  setup2FA() {
    this.apiService.get2FAStatus(this.pollId, this.privateKey).subscribe((res) => {
      console.log(res);
    },
    (err: any) => {
      // IF THE ERROR IS DIFFERENT, THE NORMAL PAGE (but empty) IS DISPLAYED
      if (err.status === 426) {
        this.isValidated = false;
        this.getVoterInfo();
      }
    });
  }

  shouldNotUpdateStatusOnRefresh(): boolean {
    return (this.status !== PollStatus.voted && this.status !== PollStatus.voteFailed) && this.liveActiveResolution !== -1;
  }

  sha(data) {
    const s = JSON.stringify(data);
    return SHA256(s).toString(enc.Hex);
  }

  getVotedStatus() {
    this.apiService.getVotedStatus(this.pollId, this.validation).subscribe((res: Array<Date>) => {
      this.votedStatuses = res.map(r => r !== null) ;
      if (this.votedStatuses.indexOf(false) === -1 || (this.isLive && res[this.liveActiveResolution])) {
        if ((!this.isLive || (this.isLive && this.shouldNotUpdateStatusOnRefresh()))) {
          console.log(res[this.liveActiveResolution])
          const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
          const dateString = new Date(res[this.liveActiveResolution]).toLocaleTimeString('fr-FR', options);
          if (this.status !== PollStatus.voted) { this.createAlreadyVotedError(dateString); }
        }
      } else {
        if (this.isLive && this.forceRefreshVotedMessage) { this.status = PollStatus.list; }
      }
    });
  }

  hasVotedOnEveryRound() { return this.votedStatuses.indexOf(false) === -1; }

  createAlreadyVotedError(date: string = null) {
    this.status = PollStatus.alreadyVoted;
    this.getVotingError('voted', date);
  }

  createBlankVoteError() {
    this.status = PollStatus.voteFailed;
    this.getVotingError('blankVote');
  }

  refreshPoll() {
    this.retry();
    this.responses = [];
    this.selectedResolution = null;
    this.selectedChoice = null;
    this.choices = [];
    this.titleList = [];
    this.cryptoChoices = [];
    this.status = PollStatus.list;
    this.getPollData();
  }

  getVoterInfo() {
    this.apiService.getVoterInfo(this.pollId, this.privateKey).subscribe((res) => {
      this.userPhoneNumber = res.phone;
      this.userRetriesRemaining = res.retries;
    },
    (err: any) => {
      console.log(err);
      // if (err.error.error)
      this.noMoreSMS = true;
    });
  }

  shouldDisplayBackButton() {
    return !(this.status in interfaceEnums.StatusesWithoutBackButton);
  }

  shouldUseLongSeparator() {
    return !(this.status in interfaceEnums.StatusesThatShouldNotUseLongLine);
  }

shouldDisplayElectionTitle() {
  return this.status in interfaceEnums.StatusesThatShouldDisplayElectionTitle;
}

shouldNotDisplayElectionTitle() {
  return this.status in interfaceEnums.StatusesWithoutConfirmVote;
}

getMessageDisplayLanguage(): string {
  switch(this.getUserLanguage().substring(0, 2)) {
    case 'fr': return 'fr';
    case 'es': return 'es';
    default: return 'en';
  }
}

getUserLanguage(): string {
    const languageKey = 'userLanguage';
    return navigator.language || navigator[languageKey];
}

isMultiCorporate() {
  if (this.electionDataJson != null) {
    return this.electionDataJson.rounds.length > 1 && this.electionDataJson.type === 'list';
  } else {
    return false;
  }
}

plurionominalShouldAddBack() { return this.isMultiCorporate() && (this.shouldDisplayElectionTitle() || this.status === PollStatus.voted) && this.status !== PollStatus.eventList }

getNextVotingRound(): number { return this.votedStatuses.indexOf(false); }

goToNextEvent() {
  const newRound = this.getNextVotingRound();
  this.eventSelection(newRound);
}

setPollRound(round: number) {
  if (round !== -1) {
    this.activePoll = new Poll(this.electionDataJson, round);
    this.setUpParticipation(round);
  }
  this.blockchainUrl = environment.settings.validation + this.pollId + '/' + round;
}

setUpParticipation(round: number) {
  if (round !== -1) {
    this.apiService.getParticipationRound(this.pollId, round).subscribe((res: ParticipationResponse) => {
      this.electionDataJson.votes = res.votes;
      this.electionDataJson.voters = res.voters;
      this.participation = Math.round(
        (this.electionDataJson.votes / this.electionDataJson.voters) * 100
      );
    });
  } else {
    this.participation = Math.round(
      (this.electionDataJson.votes / this.electionDataJson.voters) * 100
    );
  }
}

  ngOnInit() {
    this.isValidated = true;
    this.isMobile = this.common.checkIfMobile();
    this.route.queryParams.subscribe((params) => {
      this.pollId = params.electionId;
      this.getPollData();
      this.privateKey = params.key;
      this.validation = params.validation;
      if (!this.isLive && this.votingProof !== undefined) { this.getVoteHash(); }
    });
  }
}
