import {Component, Input, Output, EventEmitter, AfterViewInit, OnChanges} from '@angular/core';
import {Choice, ChoiceSelection , ChoiceUnselection} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-detail-list',
  templateUrl: './poll-detail-list.component.html',
  styleUrls: ['./poll-detail-list.component.less', '../../shared/common.less'],
})
export class PollDetailListComponent implements AfterViewInit, OnChanges {
  @Input() numberOfVote: number;
  @Input() electionTitle: string;
  @Input() liveSubjectLink: string;
  @Input() votes: number;
  @Input() previousVotes: Array<number>;
  @Input() choices: Choice[];
  @Input() isMobile: boolean;
  @Input() isLive: boolean;
  @Input() validPlurinominal: boolean;
  @Input() isForceBlankVote = false;
  @Input() liveActiveResolution: number;
  @Output() unselected = new EventEmitter<ChoiceUnselection>();
  @Output() simpleSelect = new EventEmitter<ChoiceSelection>();
  @Output() plurinominal = new EventEmitter<ChoiceSelection>();
  @Output() refresh = new EventEmitter<boolean>();
  @Input() displayedCandidates: number;
  widthPercentage = 0;
  isOthersMode = false;

  constructor() {}

  plurinominalMethod(event: ChoiceSelection) {
    event.roundIndex = this.liveActiveResolution;
    this.plurinominal.emit(event);
  }

  plurinominalUnselect(event: number) {
    this.unselected.emit(new ChoiceUnselection(event, this.liveActiveResolution));
  }

  select(event: ChoiceSelection) {
    event.roundIndex = this.liveActiveResolution;
    this.simpleSelect.emit(event);
  }

  refreshList() {
    this.refresh.emit(true);
  }

  fillVotesLine() {
    const choiceCount = this.votes != null ? this.votes : 0;
    const width = (choiceCount / this.numberOfVote) * 100 + '%';
    const tmp = parseInt(width.substr(0, width.length - 1), 10);
    let i = this.widthPercentage;
    this.widthPercentage =  parseInt(width.substr(0, width.length - 1), 10);
    const id = setInterval(() => {
      if (i <= tmp) {
        i++;
        const line = document.getElementById('voted-line') as HTMLElement;
        if (line != null) { line.style.width = i + '%'; }
        if (i === tmp) {
            clearInterval(id);
          }
        }
      if (i >= tmp) {
        i--;
        const line = document.getElementById('voted-line') as HTMLElement;
        if (line !== null) { line.style.width = i + '%'; }
        if (i === tmp) {
          clearInterval(id);
        }
      }
    }, 10);
    // line.style.width = (this.votes / this.numberOfVote ) * 100 + "%"
  }

  replaceURLs(message: string) {
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return hyperlink;
      });
  }
  getChoices(): Array<Choice> {
    if (isNaN(this.displayedCandidates)) { return this.choices; }
    else  {
      if (!this.isOthersMode) { return this.choices.slice(0, this.displayedCandidates); }
      else { return this.choices.slice(this.displayedCandidates, this.choices.length); }
    }
  }
  getOffset(): number {
    if (isNaN(this.displayedCandidates) || !this.isOthersMode) { return 0; }
    else { return this.displayedCandidates; }
  }
  displayAll() {
    const checkboxes = document.getElementsByClassName('box') as HTMLCollectionOf<HTMLInputElement>;
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) { checkboxes[i].click(); }
    }
    this.isOthersMode = !this.isOthersMode;
  }

  displayShowMore(): boolean { return !isNaN(this.displayedCandidates); }

  ngOnChanges() {
    if (this.liveActiveResolution !== -1) {
      this.fillVotesLine();
    }
  }

  ngAfterViewInit() {
    for (const vote of this.previousVotes) {
      const circle = document.getElementById('circle-' + vote) as HTMLElement;
      if (circle != null) { circle.style.backgroundImage = 'url(../../../assets/circle-fill.svg)'; }
      const input = document.getElementById('checkbox-' + vote) as HTMLInputElement;
      input.checked = true;
    }
  }

}
