import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { environment } from '../../environments/environment';

export interface Message {
  author: string;
  message: string;
}

@Injectable()
export class LiveService {
  public messages: Subject<Message>;
  currentUser: any;

  constructor(private wsService: WebsocketService) {
  }

  connect(pollId: string, token: string) {
    return this.wsService.connect(`${environment.settings.livews}${pollId}/vote/${token}`);
  }

  send(data: string, pollId: string, token: string) {
    const connection$ = this.wsService.connect(`${environment.settings.livews}${pollId}/vote/${token}`);
    if (connection$) {
      connection$.next(data);
    } else {
      console.error('Did not send data, open a connection first');
    }
  }
}
