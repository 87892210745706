import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Choice} from '../../../shared/model/poll.model';
import {Common} from '../../../shared/common';

@Component({
  selector: 'app-poll-corporate-item',
  templateUrl: './poll-corporate-item.component.html',
  styleUrls: ['./../../poll-detail-resolutions-list/poll-resolution-item/poll-resolution-item.component.less', './poll-corporate-item.component.less'],
})
export class PollCorporateItemComponent {

  @Input() choice: Choice;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() isFinal: boolean;
  @Input() isSelectedList: boolean;
  @Input() votedData: number[];
  @Output() selected = new EventEmitter<number>();
  imageAsset = 'assets/fleche_resolution_orange.svg';


  constructor(public common: Common) {}

  actionVote(force: boolean, $event) {
    if ($event.target.localName != 'a') {
      if (force || !this.isFinal) {
        // window.scrollTo(0, 0);
        this.selected.emit(this.index);
      }
    }
  }

  replaceURLs(message: string) {
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return hyperlink
      });
  }
}
