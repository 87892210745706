import {Component, Input, Output, EventEmitter, OnInit, OnChanges, AfterViewInit} from '@angular/core';
import {Resolution} from '../../../shared/model/poll.model';

@Component({
  selector: 'app-poll-resolution-item',
  templateUrl: './poll-resolution-item.component.html',
  styleUrls: ['./poll-resolution-item.component.less'],
})
export class PollResolutionItemComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() resolution: Resolution;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() votedData: number[];
  @Input() resolutionImages: Array<string>;
  @Input() images: Array<any>;
  @Output() selected = new EventEmitter<Resolution>();
  imageAsset = 'assets/fleche_resolution_orange.svg';
  isUnanswered = true;

  actionVote($event) {
    if ($event.srcElement.localName != 'a') {
      window.scrollTo(0, 0);
      this.selected.emit(this.resolution);
    }
  }

  replaceURLs(message: string) {
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return hyperlink;
      });
  }


  setupIcons() {
    this.isUnanswered = true;
    this.imageAsset = 'assets/fleche_resolution_orange.svg';
    if (this.votedData != null) {
      if (this.votedData[0] === 1) {
        this.imageAsset = 'assets/pouce_levee_resolution.svg';
        this.isUnanswered = false;
      }
      if (this.votedData[1] === 1) {
        this.imageAsset = 'assets/pouce_baissee_orange.svg';
        this.isUnanswered = false;
      }
      if (this.votedData[2] === 1) {
        this.imageAsset = 'assets/abstention_resolution.svg';
        this.isUnanswered = false;
      }
    }
  }

  loadImages() {
    if (this.images[this.index] != null) {
      const imageWrapper = document.getElementById(`image-wrapper-${this.index}`) as HTMLImageElement;
      if (imageWrapper != null) {
        imageWrapper.style.display = 'block';
      }
      const indexValue = document.getElementById(`wrapper-${this.index}`) as HTMLImageElement;
      if (indexValue != null) {
        indexValue.style.display = 'none';
      }
      const logo = document.getElementById(`resolution-${this.index}`) as HTMLImageElement;
      if (logo != null) {
        logo.src = this.images[this.index];
      }
    }
  }

  ngAfterViewInit() {
    const imageWrapper = document.getElementById(`image-wrapper-${this.index}`) as HTMLImageElement;
    imageWrapper.style.display = 'none';
    const ids = document.getElementsByClassName('resolution-index') as HTMLCollectionOf<HTMLElement>;
    if (ids.length > 0) {
      const result = [...[ids]];
      if (result[0][result[0].length - 1].id.length === 2) {
        result[0][result[0].length - 1].style.fontSize = '14px';
      }
      if (result[0][result[0].length - 1].id.length === 3) {
        result[0][result[0].length - 1].style.fontSize = '9px';
      }
    }
    this.loadImages();
  }

  ngOnChanges() {
    this.setupIcons();
    this.loadImages();
  }

  ngOnInit() {
    this.setupIcons();
  }
}
