import {Component, Input, Output, EventEmitter, OnChanges, AfterViewInit, AfterViewChecked} from '@angular/core';
import {Choice, ChoiceSelection} from '../../shared/model/poll.model';
import {Common} from '../../shared/common';

@Component({
  selector: 'app-poll-detail-choice-plurinominal',
  templateUrl: './poll-detail-choice-plurinominal.component.html',
  styleUrls: ['./poll-detail-choice-plurinominal.component.less'],
})
export class PollDetailChoicePlurinominalComponent implements OnChanges, AfterViewInit {
  @Input() choice: Choice;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() numberOfVote: number;
  @Input() validPlurinominal: boolean;
  @Input() isForceBlankVote = false;
  @Input() lastIndex: number;
  @Input() image: string;
  @Output() selected = new EventEmitter<ChoiceSelection>();
  @Output() unselected = new EventEmitter<number>();
  expanded = false;

  constructor(private common: Common) {
  }

  replaceURLs(message: string) {
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return hyperlink;
      });
  }

  hoverEvent(event: boolean) {
    const textHolder = document.getElementById(`poll-item-question-${this.index}`) as HTMLSpanElement;
    if (textHolder.scrollHeight > 60 && !this.common.checkIfMobile()) {
      this.expanded = event;
      const pageContainer = document.getElementById('content-wrap') as HTMLDivElement;
      const parentWidth = window.innerWidth - pageContainer.getBoundingClientRect().right - 48;
      const scrollBar = document.getElementById(`rollover-${this.index}`) as HTMLSpanElement;
      scrollBar.style.width = `${parentWidth}px`;
      const heightParent = document.getElementById(`poll-item-question-${this.index}`) as HTMLSpanElement;
      scrollBar.style.top = `${this.getPageOffset(heightParent) - 6}px`;
    }
  }

   getPageOffset(elem: HTMLElement) {
    let topOffset = elem.getBoundingClientRect().top;
    while (elem != document.documentElement) {
      elem = elem.parentElement;
      topOffset += elem.scrollTop;
    }
    return topOffset;
  }

  isForceBlankVoteSituation() {
    if (this.isForceBlankVote) {
      const lastInput = document.getElementById('checkbox-' + this.lastIndex) as HTMLInputElement;
      return lastInput.checked;
    }
    return false;
  }

  actionVote(index: number) {
    const circle = document.getElementById('circle-' + index) as HTMLElement;
    const input = document.getElementById('checkbox-' + index) as HTMLInputElement;
    if (!this.validPlurinominal && !this.isForceBlankVoteSituation()) {
      if (!(this.choice.image != undefined && this.choice.image != 'null' && this.choice.image.length > 0)) {
        circle.style.backgroundImage = 'url(../../../assets/circle.svg)';
      }
      input.checked = false;
      this.unselected.emit(index);
      } else {
      if (!(this.choice.image != undefined && this.choice.image != 'null' && this.choice.image.length > 0)) {
        circle.style.backgroundImage = 'url(../../../assets/circle-fill.svg)';
        // hack to avoid the first circle after force blank vote being weird
        if (this.isForceBlankVoteSituation()) {
          const timer = setTimeout(() => {
            if (input.checked) {
              circle.style.backgroundImage = 'url(../../../assets/circle-fill.svg)';
            }
          }, 500);
        }
      }
      this.selected.emit(new ChoiceSelection(this.choice, index, null));
    }
  }

  loadImages() {
    if (this.choice.image != undefined && this.choice.image != 'null' && this.choice.image.length > 0) {
      const imageWrapper = document.getElementById(`image-wrapper-${this.index}`) as HTMLImageElement
      if (imageWrapper != null) {
        imageWrapper.style.display = 'block';
      }
      const indexValue = document.getElementById(`wrapper-${this.index}`) as HTMLImageElement;
      if (indexValue != null) {
        indexValue.style.display = 'none';
      }
      const logo = document.getElementById(`resolution-${this.index}`) as HTMLImageElement
      if (logo != null) {
        logo.src = this.choice.image[this.index];
      }
    }
  }

  ngOnChanges() {
    if (this.choice.image != undefined && this.choice.image != 'null' && this.choice.image.length > 0) {
        const logo = document.getElementById(`resolution-${this.index}`) as HTMLImageElement;
        if (logo != undefined) {
          logo.src = this.choice.image;
        }
    }
  }

  ngAfterViewInit() {
    if (this.choice.image != undefined && this.choice.image != 'null' && this.choice.image.length > 0) {
      const logo = document.getElementById(`resolution-${this.index}`) as HTMLImageElement;
      if (logo != undefined) { logo.src = this.choice.image; }
    } else {
        const ids = document.getElementById((this.index + 1).toString());
        if ((this.index + 1).toString().length === 2) { ids.style.fontSize = '14px'; }
        if ((this.index + 1).toString().length === 3) { ids.style.fontSize = '10px'; }
      }
  }
}
