import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Result, ResolutionResult, PollQuestion, VotingEvent, ListResult } from '../../shared/model/poll.model';
import { ApiService } from '../../shared/api.service';

@Component({
  selector: 'app-results-holder',
  templateUrl: 'result-holder.component.html',
  styleUrls: ['./result-holder.component.less']
})

export class PollResultsHolderComponent implements OnInit {
  @Input() pollId: string;
  @Input() displayIndex: number;
  @Input() blockchainUrl: string;
  title: string;
  privateKey: string;
  isResolution = false;
  question = '';
  resolutionResults: Array<ResolutionResult> = [];
  listResults: Array<Array<ListResult>> = [];
  results: Result[] = [];
  resultsArray: Array<Result[]> = [];
  responses: number[] = [];
  calculating = false;
  type: any;
  electionNames: Array<string> = [];

  constructor(private router: Router, private apiService: ApiService) {
  }

  getElectionCalculated() {
    this.apiService.getResults(this.pollId).subscribe((res: Array<any>) => {
      if (Object.keys(res).length === 0){
        this.calculating = true;
        const self = this;
        setTimeout(() => {
            self.getElectionCalculated();
          }, 5000);
      } else {
        this.calculating = false;
        const votes: number[] = res[0];
        let totalVotes = 0;
        for (const vote of votes) {
          totalVotes += vote;
        }

        res.forEach(function (result, i) {
          this.resultsArray[i].forEach(function (answer, s) {
            const participarionNumber = Math.round(result[s] / totalVotes * 100);
            answer.percentage = isNaN(participarionNumber) ? 0 : parseFloat((result[s] / totalVotes * 100).toFixed(2));
            answer.votes = result[s];
          }, this)
        }, this)
        this.results.sort((a, b) => {
          if (a.percentage > b.percentage) { return -1; }
          if (a.percentage < b.percentage) { return 1; }
          return 0;
        });
      }
    });
  }

  getResolutionCalculated() {
    this.apiService.getResults(this.pollId).subscribe((res: Array<Array<number>>) => {
      if (Object.keys(res).length === 0) {
        const self = this;
        this.calculating = true;
        setTimeout(() => {
            self.getResolutionCalculated();
          }, 5000);
      }
      else {
        this.calculating = false;
        for (const roundIndex in res) {
          if (res.hasOwnProperty(roundIndex)) {
            let totalVotes = 0;
            const shouldHideNoAnswer = (this.type === 'resolution' && res[roundIndex].length === 4) ||
              (this.type === 'live' && res[roundIndex].length === 4);
            const limit = shouldHideNoAnswer ? res[roundIndex].length - 1 :  res[roundIndex].length;
            if (limit < res[roundIndex].length) {
              this.resolutionResults[roundIndex].answers.pop();
              this.resolutionResults[roundIndex].results.pop();
            }
            for (let j = 0; j < limit; j++) {
              totalVotes += res[roundIndex][j];
            }
            for (let j = 0; j < limit; j++) {
              if (totalVotes > 0) {
                this.resolutionResults[roundIndex].results[j].percentage = parseFloat((res[roundIndex][j] / totalVotes * 100).toFixed(2));
                this.resolutionResults[roundIndex].results[j].votes = res[roundIndex][j];
              } else {
                this.resolutionResults[roundIndex].results[j].percentage = 0;
                this.resolutionResults[roundIndex].results[j].votes = 0;
              }
            }
            this.resolutionResults[roundIndex].results.sort((a, b) => {
                if (a.percentage > b.percentage) { return -1; }
                if (a.percentage < b.percentage) { return 1; }
                return 0;
            });
          }
        }
      }
    });
  }

  getListCalculated() {
    this.apiService.getResults(this.pollId).subscribe((res: Array<Array<number>>) => {
      if (Object.keys(res).length === 0) {
        const self = this;setTimeout(() => {
            self.getListCalculated();
          }, 5000);
      }
      else {
        let offset = 0;

        this.listResults.forEach((round) => {
            round.forEach((value, index) => {
              value.results = value.answers.map((x, i) => new Result(x, parseFloat((res[index + offset][i] / value.votes * 100).toFixed(2)), res[index + offset][i]));
            });
            offset += round.length;
        });
        this.calculating = false;
      }
    });
}

  ngOnInit() {
    this.apiService.getPoll(this.pollId).subscribe((res: VotingEvent) => {
      this.question = res.description;
      this.title = res.name;
      this.type = res.type;
      if (res.type === 'planned' || (res.rounds[0].questions.length === 1 && (res.type === '' || res.type === 'election'))) {
        const responseQuestions: string[] = res.rounds[0].bc && res.rounds[0].displayedQuestions[0].numberOfVote > 1 ? res.rounds[0].displayedQuestions[0].answers : res.rounds[0].questions[0].answers;
        if (res.isLive && res.type === 'election') {
          for (const answers of res.rounds) {
            this.electionNames.push(answers.questions[0].question);
            for (const answer of answers.displayedQuestions[0].answers) {
              const newAnswer = new Result(answer, 0, 0);
              this.results.push(newAnswer);
            }
            this.resultsArray.push(this.results);
            this.results = [];
          }
          this.getElectionCalculated();
        } else {
          if (responseQuestions != null) {
            this.results = [];
            for (const answer of responseQuestions) {
              const newAnswer = new Result(answer, 0, 0);
              this.results.push(newAnswer);
            }
            this.resultsArray.push(this.results)
          this.getElectionCalculated();
          }
          for (const answers of res.rounds) {
            this.electionNames.push(answers.questions[0].question)
          }
        }

      }
      if (res.type === 'list') {
        this.calculating = true;
        this.listResults = res.rounds.map(round => round.displayedQuestions.map(x => new ListResult(x.question, x.answers, [], x.id, x.parent, round.votes)))
        this.electionNames = res.rounds.map(round => round.title );

        this.getListCalculated();
      }
      const isLegacyResolution = res.rounds[0].questions.length > 1 && (res.type === '' || res.type === 'planned');
      const isLiveCandidate = res.type === 'liveCandidate' || (res.type === 'election' && res.rounds[0].questions.length > 1);
      if (res.type === 'resolution' || res.type === 'live' || isLiveCandidate || isLegacyResolution) {
        this.isResolution = true;
        for (const round of res.rounds) {
          const displayedQuestionsArray: Array<PollQuestion> = round.displayedQuestions;
          const questionsArray: Array<PollQuestion> = round.questions;
          for (const questionIt of questionsArray) {
            const tempResults: Result[] = [];
            const questionMask = displayedQuestionsArray.filter(x => x.question === questionIt.question)[0];
            const newQuestion: PollQuestion = questionMask != null && questionMask !== undefined ? questionMask : questionIt;
            const responseQuestions: string[] = newQuestion.answers;
            for (const answer of responseQuestions) {
              const newAnswer = new Result(answer, 0, 0);
              tempResults.push(newAnswer);
            }
            const tempResult = new ResolutionResult(questionIt.question, responseQuestions, tempResults);
            this.resolutionResults.push(tempResult);
          }
        }
        this.getResolutionCalculated();
      }
     });
  }
}
