<!--<div *ngIf="status != statuses.multielectionRoundSelected" id="separator"></div> -->
<div *ngIf="isLive && liveActiveResolution == -1"  id="separator"></div>
<div *ngIf="isLive && liveActiveResolution == -1" class="wait-text">
  {{'POLL-LIST.WAITING' | translate}}
</div>
<div *ngIf="isLive" class="button-refresh" (click)="refreshList()">
 <img src="assets/refresh_24px.svg">
 <span class="refresh-text">{{'POLL-LIST.REFRESH' | translate}}</span>
</div>
<div *ngIf="liveActiveResolution !== -1" id="firstLine">
 <div class="bar-progression">
   <h3 class="votes">Votes</h3>
   <p class="count">{{votes}} / {{numberOfVote}}</p>
   <div class="lines">
     <div class="line empty-line"></div>
     <div class="line" id="voted-line"></div>
   </div>
 </div>
   <!-- <p *ngIf="show === true && !isLive" class="description">{{question === '' ? '?' : question}}</p> -->
    <!-- <p *ngIf="show === false && !isLive" class="description-long">{{question === '' ? '?' : question}}</p> -->
</div>

<div *ngIf="displayShowMore() && this.isOthersMode" class="see-more-text" (click)="displayAll()">{{'POLL-CHOICE.SEE-LESS' | translate}}</div>
<div *ngIf="electionTitle != undefined" [ngClass]="isMobile == true ? 'resolutions-holder-mob' : 'resolutions-holder'">

  <h3 *ngIf="liveSubjectLink == null
            || (liveSubjectLink != undefined && liveSubjectLink.length == 0) else displayLink" id="resolutions-header">
    {{electionTitle.length >= 1 ? electionTitle : 'POLL-CHOICE.HEADER' | translate}}
  </h3>

  <ng-template #displayLink>
    <div id="link-wrapper">
      <a class="poll-item-question" href="{{replaceURLs(liveSubjectLink)}}" target="_blank">
        {{electionTitle != undefined && electionTitle.length >= 1 ? electionTitle : 'POLL-CHOICE.HEADER' | translate}}
      </a>
    </div>
  </ng-template>

  <div *ngIf="numberOfVote < 2">
    <!-- <div *ngFor="let choice of choices; let i = index">
      <p>{{choice.image}}</p>
    </div> -->
    <app-poll-detail-choice-plurinominal (selected)="select($event)" *ngFor="let choice of getChoices(); let i = index" [index]="i + getOffset()"
      [validPlurinominal]="validPlurinominal" [isMobile]="isMobile" [choice]="choice" [numberOfVote]="numberOfVote">
    </app-poll-detail-choice-plurinominal>
  </div>
  <div *ngIf="numberOfVote >= 2">
    <app-poll-detail-choice-plurinominal (unselected)="plurinominalUnselect($event)"
      (selected)="plurinominalMethod($event)" *ngFor="let choice of getChoices(); let i = index" [index]="i" [lastIndex]="getChoices().length - 1"
      [validPlurinominal]="validPlurinominal" [isMobile]="isMobile" [choice]="choice" [numberOfVote]="numberOfVote" [isForceBlankVote]="isForceBlankVote">
    </app-poll-detail-choice-plurinominal>
  </div>
</div>

<div *ngIf="displayShowMore() && !this.isOthersMode" class="see-more-text" (click)="displayAll()">{{'POLL-CHOICE.SEE-MORE' | translate}}</div>
<div *ngIf="displayShowMore() && this.isOthersMode" class="see-more-text" (click)="displayAll()">{{'POLL-CHOICE.SEE-LESS' | translate}}</div>
