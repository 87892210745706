export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAY8vB8O5oG4WwHUL310u_JwqtC1X4iApU",
    authDomain: "v8te-a9b50.firebaseapp.com",
    databaseURL: "https://v8te-a9b50.firebaseio.com",
    projectId: "v8te-a9b50",
    storageBucket: "v8te-a9b50.appspot.com",
    messagingSenderId: "418674463756",
    appId: "1:418674463756:web:8f110be6b454dca9bb0a29",
    measurementId: "G-P7SZ00CVHN"
  },
  settings: {
    backend: 'https://api.beta.v8te.com/',
    livews: 'wss://api.beta.v8te.com/ws/elections/',
    validation: 'https://validation.beta.v8te.com/',
    assessorValidation: 'https://www.beta.v8te.com/'
  }
};
