import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-poll-vote-resolution-footer',
  templateUrl: './poll-vote-resolution-footer.component.html',
  styleUrls: ['./poll-vote-resolution-footer.component.less', '../../shared/common.less'],
})

export class PollVoteResolutionFooterComponent {
  @Input() isMobile: boolean;
  @Input() validButton = true;
  @Output() selected = new EventEmitter<string>();

  actionVoted() {
    this.selected.emit('voted');
  }

  actionCancel() {
    this.selected.emit('cancel');
  }
}
