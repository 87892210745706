import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'vc-recovery',
  templateUrl: 'recovery.component.html',
  styleUrls: ['./recovery.component.less']
})

export class RecoveryComponent {
  user: string;
  isError = false;
  isMobile = false;
  errorMessage: string;
  constructor(private router: Router,  private apiService: ApiService) {
  }

  onUser(event) {
    this.user = event.target.value;
  }

  dismissError() {
    this.isError = false;
  }


  login() {
    this.apiService.recoverEmail(this.user).subscribe((res) => {
      this.isError = true;
      this.errorMessage = ' Votre invitation vous a été renvoyée sur votre e-mail ';
    }, (err: any) => {
      this.errorMessage = ' Désolé, cette adresse e-mail ne correspond à aucune invitation ';
      this.isError = true;
    });
  }
}
