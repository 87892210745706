import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ReceivedQuestion} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-corporate-details',
  templateUrl: './poll-corporate-details.component.html',
  styleUrls: ['./../poll-detail-list/poll-detail-list.component.less', '../../shared/common.less'],
})

export class PollCorporateDetailsComponent implements OnInit {
  @Input() electionTitle: string;
  @Input() isMobile: boolean;
  @Input() questions: ReceivedQuestion[];
  @Input() answers: number[];
  @Output() sendListSelection = new EventEmitter<Array<number>>();
  @Output() sendListCancel = new EventEmitter<boolean>();
  localAnswers: number[];
  isValidList = true;

  getSelection($event: number){
    this.localAnswers[$event] = this.localAnswers[$event] === 1 ? 0 : 1;
    this.isValidList = this.localAnswers.includes(1);

  }

  ngOnInit() {
    this.localAnswers = Object.assign([], this.answers);
  }

  choiceConfirmed($event: string) {
    $event === 'voted' ? this.sendListSelection.emit(this.localAnswers) : this.sendListCancel.emit(true);
  }
}
