import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-assessors-popup',
  templateUrl: 'assessors-popup.component.html',
  styleUrls: ['./assessors-popup.component.less', '../shared/common.less']
})
export class AssessorsPopupComponent {
  @Input() title: string;
  @Input() description: string;
}
