import {Component, Input} from '@angular/core';
import {Result} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-results-list',
  templateUrl: './poll-results-list.component.html',
  styleUrls: ['./poll-results-list.component.less'],
  styles: [`
    ngb-progressbar {
      margin-top: 5rem;
    }
  `]
})
export class PollResultsListComponent {
  @Input() results: Result[];
  @Input() electionNames: Array<string>;

  ngAfterViewChecked() {
    // console.log(this.results);

  }
}
