import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ReceivedQuestion} from '../../shared/model/poll.model';

@Component({
  selector: 'app-elections-list',
  templateUrl: './poll-elections-list.component.html',
  styleUrls: ['./../poll-detail-resolutions-list/poll-detail-resolutions-list.component.less', '../../shared/common.less'],
})
export class PollElectionsListComponent implements OnInit {

  @Input() elections: ReceivedQuestion[];
  @Input() isMobile: boolean;
  @Input() responses: number[][];
  answeredCount = 0;
  percentage = 0;
  @Input() forceBlankVote = false;
  @Output() selected = new EventEmitter<number>();



  constructor() {
  }

  setActiveElection(event: number) {
    this.selected.emit(event);
    this.calculateUnanswered();
  }

  calculateUnanswered() {
    for (const response of this.responses) {
      if (response.length !== 0) {
          this.answeredCount = this.answeredCount + 1;
      }
    }
    this.percentage = Math.round(this.answeredCount / this.elections.length * 100);
  }

  ngOnInit() {
    this.calculateUnanswered();
  }

}
